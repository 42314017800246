<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <!-- 원시데이터 Excel Upload ---------------------------------------------------------------------------------------->
      <BCard header-tag="header" footer-tag="footer">

        <div slot="header">
          <BIconUpload/> <strong> 주문 데이터 파일 업로드 </strong>
          <div class="card-header-actions">
            <small class="text-muted"></small>
          </div>
        </div>

        <BCard>
          <BRow class="mb-2">
            <BCol>
              <BInputGroup prepend="주문 데이터 파일">
                <BFormFile
                  size="lg"
                  id="excelFileUploadForm"
                  v-model="excelFiles"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  :state="Boolean(excelFiles)"
                  multiple
                  placeholder="엑셀 파일 업로드"
                  drop-placeholder="여기에 드래그 & 드랍"
                  @input="excelFileSelected"
                >
                </BFormFile>

              </BInputGroup>
            </BCol>

          </BRow>

          <!-- excel table -->
          <BTable small sticky-header selectable hover striped responsive bordered
                  class="small text-nowrap"
                  thead-class="text-center"
                  ref="excelTable"
                  id="excelTable"
                  select-mode="multi"
                  selectedVariant="success"
                  :items="excelItems"
                  :fields="excelFields"
                  :key="excelTableKey"
                  @row-selected="excelRowSelected">
          </BTable>

          <BRow class="mb-3">
            <BCol>
              <BButtonGroup>
                <BButton variant="success" @click="selectAllRecord">전체 선택 </BButton>
                <BButton variant="dark" @click="deSelectAllRecord">전체 선택 해제</BButton>
                <BButton class="ml-2" variant="warning" @click="clickImportRecords" :disabled="uploadItems.length===0">
                  {{uploadItems.length +' 개 파일'}} 업로드 실행
                  <b-spinner small type="grow" v-if="isItemLoading"></b-spinner>
                </BButton>
                <BButton class="ml-2"
                         variant="danger"
                         @click="clickExcelClear"
                         v-show="excelFiles.length > 0">
                  <BIconTrash2Fill/> 리셋
                </BButton>

              </BButtonGroup>

            </BCol>
            <BCol>
              <BProgress height="2rem" :value="uploadedCount" :max="totalCount" variant="warning" show-value/>
            </BCol>
          </BRow>
        </BCard>

        <BCard>
          <BTable small sticky-header selectable hover striped responsive bordered
                  class="small text-nowrap"
                  thead-class="text-center"
                  ref="logTable"
                  id="logTable"
                  selectedVariant="primary"
                  select-mode="single"
                  :items="logItems"
                  :fields="logFields"
                  :per-page="logPerPage"
                  :current-page="logCurrPage"
                  @row-selected="rawFileSelected"
          >
            <template v-slot:cell(progress)="data">
              <BProgress height="1rem"
                         size="small"
                         :value="pStat[data.item.file_seq].count"
                         :max="pStat[data.item.file_seq].total"
                         :key="progressKey"
                         variant="primary" show-value/>
            </template>
          </BTable>
          <BRow>
            <BCol>
              <BPagination
                class="small"
                v-model="logCurrPage"
                :total-rows="logRows"
                :per-page="logPerPage"
                aria-controls="logTable"
              ></BPagination>
            </BCol>
            <BCol class="text-right">
              <BButton :variant="(isProcessing)?'danger':'warning'"
                       @click="clickTransBtn"
                       :disabled="isProcessing"
                       v-show="rawFile!=null">
                <BIconCloudCheckFill v-show="isProcessing===false"/>
                <b-spinner small type="grow" v-if="isProcessing"></b-spinner>
                변환 실행
              </BButton>
            </BCol>
          </BRow>

          <BRow>
            <BCol>
              <b-form-textarea readonly
                id="messageBox"
                ref="messageBox"
                v-model="socketMessage"
                placeholder="업로드 로그"
                rows="5"
                max-rows="30"
              ></b-form-textarea>
            </BCol>
          </BRow>
        </BCard>

      </BCard>


    </div>
  </div>
</template>

<script>
//-------------------------------------------------------------------------------------------------

import {

  apiCall,
  cloneVar,
  confirmModal,
  checkBisNo,
  apiMultiPart,
  commify,
  humanSize,
  alertSuccess
} from '../../../common/utils';
import qs from 'querystring';

import moment from 'moment';


//----------------------------------------------------------------------------------------------------
export default {
  name: 'SalesUpload',

  data () {
    return {
      rawFiles: null,

      excelNotice: null,
      excelFiles: [],
      excelItems: [],
      excelFields: [
        {key:'name',    sortable:true,  label:'파일명'},
        {key:'size',    sortable:true,  label:'크기', tdClass:'text-right', formatter: v =>{ return commify(humanSize(v));}},
        {key:'lastModifiedDate',    sortable:true,  label:'최종 수정일', formatter: value => {return moment(value).format('YYYY-MM-DD HH:mm');}},
      ],
      excelTableKey: 1,
      uploadItems: [],
      isItemLoading: false,
      uploadedCount: 0,
      totalCount: 0,
      fromDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      toDate: moment().format('YYYY-MM-DD HH:mm:ss'),

      logItems: [],
      logFields: [
        { key:'file_seq'        ,sortable: true, label:'번호'},
        { key:'org_file_name'   ,sortable: true, label:'원시 파일명'},
        { key:'file_name'       ,sortable: true, label:'업로드 파일명'},
        { key:'size'            ,sortable: true, label:'파일크기', tdClass:'text-right', formatter: v =>{ return commify(humanSize(v));}},
        { key:'trans_yn'        ,sortable: true, label:'변환여부', tdClass:'text-center'},
        { key:'rec_count'       ,sortable: true, label:'레코드 수', tdClass:'text-right', formatter: v=>{return commify(v)}},
        { key:'trans_result'    ,sortable: true, label:'변환결과'},
        { key:'progress'        ,sortable: false, label:'진행경과 그래프'},
        { key:'reg_dt'          ,sortable: true, label:'업로드일시', tdClass: 'text-center', formatter: value => {return moment(value).format('YYYY-MM-DD HH:mm')}},
        { key:'upd_dt'          ,sortable: true, label:'변환일시', tdClass: 'text-center',  formatter: value => {return (value===null)?null:moment(value).format('YYYY-MM-DD HH:mm')}},
        { key:'reg_id'          ,sortable: true, label:'등록자'},

      ],
      logCurrPage: 1,
      logPerPage: 10,
      rawFile: null,
      isProcessing: false,
      progressKey: 0,
      socketMessage: "",

      searchField: null,
      searchWord: null,

      socket : null,
      status : "disconnected",
      pStat : {},


    }
  },
  created(){

    // console.log( "socket object ------------> ", this.$socket );
    this.getUploadLog();
    this.$socket.on('message', async (data)=>{
      console.log( `message from server ------->`, data );
      this.pStat[data.file_seq] = data;
      console.log(this.pStat[data.file_seq] );
      this.progressKey++;
      if( data.status==='done'){
        await alertSuccess(this.$bvModal, `총 ${data.total}개중 ${data.count}개의 주문데이터를 저장 하였습니다.`,'주문데이터 반영 완료');
        this.getUploadLog();
      }
      if( data.status==='error'){
        this.socketMessage += "\n"+ data.message;
      }

    });

  },
  computed: {
    logRows(){
      return this.logItems.length;
    },
  },

  methods: {

    excelRowSelected( item ){
      this.uploadItems = item;
    },
    selectAllRecord(){
      this.$refs['excelTable'].selectAllRows();

    },
    deSelectAllRecord(){
      this.$refs['excelTable'].clearSelected();

    },
    clickImportRecords(){
      this.uploadedCount = 0;
      const confirmMsg = {
        level:'warn',
        title: '주문데이터 업로드 확인',
        text:`${this.uploadItems.length} 개의 주문데이터를 저장합니다.\n진행 하시겠습니까?`
      };
      confirmModal( this.$bvModal, confirmMsg, (ok)=>{
        if(ok){
          this.uploadData();
          this.isItemLoading = true;
        } else {
          return;
        }
      });
    },
    clickExcelClear(){
      this.excelFiles = [];
      this.excelItems = [];
      this.uploadedCount = 0;
      this.uploadItems = []
    },
    excelFileSelected(){
      console.log("############## excelFileSelected #################");
      // console.log( this.file );
      if( !this.excelFiles) return;
      console.log( this.excelFiles );
      this.excelItems = this.excelFiles;

    },
    async uploadData(){
      this.totalCount = this.uploadItems.length;
      let formData = new FormData();
      for( let file of this.uploadItems ){
        formData.append("orderFiles", file);
      }

      try{
        const r = await apiMultiPart("/admin/api/upload/order", formData);
        console.log("apiMultiPart ----------------> result---------------");
        console.log( r );

        if( r.code===200){
          for( let rs of r.result){
            console.log(rs);
            this.uploadedCount += rs.affectedRows;
          }

          await alertSuccess(this.$bvModal, `${this.uploadItems.length} 개의 주문데이터를 저장 하였습니다.`,'주문데이터 업로드 완료');
          this.clickExcelClear();
          this.getUploadLog();

        }
      }catch(err){
        console.log(err);
      }finally{
        this.isItemLoading = false;
      }

    },

    async getUploadLog(){
      let r = null, param = {};
      let qry = '';
      if( this.searchWord !== null){
        param[this.searchField] = this.searchWord;
        qry = qs.stringify( param );
      }
      try{
        r = await apiCall('get', `/admin/api/upload/log?${qry}`);
        this.logItems = r.result;

        r.result.map((i)=>{
          this.pStat[i.file_seq] = { count:0, total:0 };
        });

      }catch(err){
        console.log( err );
      }

    },

    clickTransBtn(){

      this.checkTransYn((ok)=>{
        if(!ok) return;
        else {
          const notice = {
            level:'danger',
            title: '주문파일 변환',
            text:`${this.rawFile.file_seq} 번째 주문 파일을 데이터베이스로 변환 입력합니다.`
          };

          confirmModal( this.$bvModal, notice, (ok)=>{
            if(ok){
              this.isProcessing = true;
              this.socketMessage = '';
              this.doFileTrans();
            }else {
              return;
            }
          });
        }
      });


    },

    checkTransYn(cb){
      let notice = null;
      if(this.rawFile.trans_yn==='P'){
        notice = {
          level:'danger',
          title: '주문파일 변환',
          text:`현재 작업중 이므로 변환할 수 없습니다.\n강제로 진행 하시겠습니까?`
        };
        confirmModal( this.$bvModal, notice, (ok)=>{
          if(ok){
            cb(true);
          }else{
            this.rawFile = null;
            cb(false);
          }
        });
      }else{
        cb(true);
      }
    },


    async doFileTrans(){
      let r = null;
      try{
        r = await apiCall('post', `/admin/api/upload/import`, this.rawFile);

        console.log( r );
        if(r.code===200){
          this.getUploadLog();
        }else{
          alert( r.message );
        }

      }catch(err){
        console.log( err );

      }finally{
        this.isProcessing = false;
      }
    },

    rawFileSelected(item){
      console.log("------------ rawFileSelected ------------");
      if( item.length === 0 ) {
        this.rawFile = null;
        this.actionMode = "post";
      }else{
        this.rawFile = item[0];
        this.actionMode = "put";
        console.log( item[0] );

        if(this.$socket){
          this.$socket.emit('message', item[0]);
        }
      }
    }

  },
  beforeDestroy() {
    console.log('RawData --------------> beforeDestroy( ) ---------------');
    this.$socket.removeAllListeners('message');

  }
}
</script>
